let config = {
    s3: {
        REGION: "ap-southeast-2",
        BUCKET: "forwood-nsr-staging-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-nsr-staging-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "ap-southeast-2",
        URL: "https://rmnub4geabccpjbc2gzu6dqrcu.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "ap-southeast-2",
        URL: "https://pw666rkdm7.execute-api.ap-southeast-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://a4yxm13r3f.execute-api.ap-southeast-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.staging.nsr.forwoodsafety.com",
        WEBSOCKET: "wss://dvldmv3ov7.execute-api.ap-southeast-2.amazonaws.com/staging"
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_IpiiUgpqZ",
        IDENTITY_POOL_ID: "ap-southeast-2:41e6f353-21d5-4fa6-8e04-3706ac3c3fd3",
        USERPOOL_HOSTED_DOMAIN: "forwood-nsr-id-staging",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.staging.nsr.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.staging.nsr.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::741739526942:role/staging-CA"
    },
    samlProvider: {
      NAME: 'Nsr',
      SUPPORTED_LIST: 'Nsr,ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.35.3",
        HOSTNAME: "id.staging.nsr.forwoodsafety.com",
        COOKIE_DOMAIN: ".staging.nsr.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "a542ae2b-6931-4307-8ad6-ae03c182c6ca",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.staging.nsr.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
